import { width } from "../_function/detectDevice";

function genAccordNav($acc, cont, btn, cls) {
  const clsOpen = 'accOpen';
  const clsInner = 'accContWrap';
  const $cont = $acc.find(cont);
  const $btn = $acc.find(btn);
  $btn.on('click', function(e) {
    if (width() !== 'pc' && $(this).hasClass(cls)) {
      e.preventDefault();
      const parent = $(this).closest('li');
      const target = parent.find(cont);
      const h = target.outerHeight();
      if (!parent.hasClass(clsOpen)) {
        parent.addClass(clsOpen);
        target.parent().css('height', h);
      } else {
        parent.removeClass(clsOpen);
        target.parent().css('height', 0);
      }
    }
  });
  $(window).on('load resize', function() {
    if (width() !== 'pc') {
      $cont.each(function(i, e) {
        if (!$(e).parent().hasClass(clsInner)) {
          $(e).wrap('<div class="' + clsInner + '">');
        }
      });
    } else {
      $cont.each(function(i, e) {
        const parent = $(e).closest('li');
        parent.removeClass(clsOpen);
        $(e).css('height', '');
        if ($(e).parent().hasClass(clsInner)) {
          $(e).unwrap();
        }
      });
    }
  });
}

export default genAccordNav;