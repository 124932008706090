import { width } from "../_function/detectDevice";
import scrLock from "../_function/scrollLock";
import genAccordNav from "./accordionNav";

function nav() {
  const clsNavVis = 'navVis';
  const body = $('body');
  const header = $('header');
  const nav = $('nav');
  const inner = $('.navInner');
  const btn = $('.navBtn');
  const navListArea = nav.find('.navListArea');
  const navList = nav.find('.navList');
  // ===================
  // ドロップダウン
  // ===================
  const clsDropOpen = 'dropOpen';
  const menu = nav.find('.navList > li');
  const dropdown = navList.find('> li .dropdown');
  menu.on('mouseenter', function () {
    dropOpen($(this));
  }).on('mouseleave', function () {
    dropClose($(this));
  });
  $(window).on('resize', function() {
    dropClose(menu);
  });
  function dropOpen($this) {
    if (width() === 'pc') {
      $this.children('.dropdown').addClass(clsDropOpen);
    }
  }
  function dropClose($this) {
    $this.children('.dropdown').removeClass(clsDropOpen);
  }
  // ===================
  // スクロール追従
  // ===================
  const clsNavOpen = 'navOpen';
  const clsNavFixed = 'navFixed';
  const clsNavTran = 'navTrans';
  let scrVal = 0;
  let lock = false;
  $(window).on('load resize', function() {
    nav.css('height', inner.outerHeight());
    scrVal = $(this).scrollTop();
  });
  $(window).on('scroll', function() {
    const scr = $(this).scrollTop();
    const headerH = (width() === 'pc') ?
      header.outerHeight() + nav.outerHeight() :
      header.outerHeight() + nav.find('.navMain').outerHeight();
    if (scr > headerH) {
      body.addClass(clsNavFixed);
      if (scr - scrVal < 0) {
        body.addClass(clsNavVis);
      } else {
        if (width() === 'pc') body.removeClass(clsNavVis);
        else {
          if (!lock) {
            body.removeClass(clsNavVis);
          } else {
            lock = false;
          }
        }
      }
    } else {
      if (!body.hasClass('scrLock')) reset();
    }
    scrVal = scr;
    function reset() {
      body.removeClass(clsNavFixed)
        .removeClass(clsNavVis)
        .removeClass(clsNavTran);
      nav.css('height', '');
    }
  });
  // ===================
  // 検索窓
  // ===================
  const clsSearchOpen = 'searchOpen';
  const searchOpenBtn = $('.searchOpenBtn');
  const searchCloseBtn = $('.searchClose');
  const searchArea = $('.searchArea');
  const searchInner = searchArea.find('.searchInner');
  searchOpenBtn.on('click', function() {
    searchArea
      .addClass(clsSearchOpen).addClass('inv')
      .css('height', searchInner.outerHeight());
    searchOpenBtn.addClass('inv');
    if (width() !== 'pc') navClose();
  });
  searchCloseBtn.on('click', function() {
    searchClose();
  });
  function searchClose() {
    searchArea
      .removeClass(clsSearchOpen).removeClass('inv')
      .css('height', 0);
    searchOpenBtn.removeClass('inv');
  }
  // ===================
  // ハンバーガーメニュー
  // ===================
  btn.on('click', function() {
    if (!body.hasClass(clsNavOpen)) {
      navOpen();
    } else {
      navClose();
    }
  });
  $(window).on('load', function() {
    btn.html('<span></span><span></span><span></span>');
    dropdown.each(function(i, e) {
      const a = $(e).siblings('a');
      if (a.length > 0) {
        a.addClass('withDrop');
      }
    });
  });
  $(window).on('resize', function() {
    if (width() === 'pc') {
      navClose();
      navListArea.css('top', '');
    }
  });
  function navOpen() {
    body.addClass(clsNavOpen);
    searchClose();
    let h =  inner.find('.navMain').outerHeight();
    if (!body.hasClass(clsNavFixed)) h += header.outerHeight();
    navListArea.css('top', h);
    scrLock();
    lock = true;
  }
  function navClose() {
    body.removeClass(clsNavOpen);
    scrLock(false);
  }
  // ===================
  // アコーディオン
  // ===================
  genAccordNav(navList.find('> li'), '.dropdown', '> a', 'withDrop');
}

export default nav;