function scrLock(lock=true) {
  const body = $('body');
  if (lock) {
    const pos = $(window).scrollTop();
    body.css({
      width: '100%',
      position: 'fixed',
      top: -1 * pos,
      left: 0,
    })
      .attr('data-scroll', pos)
      .addClass('scrLock');
  } else {
    body.css({
      width: '',
      position: '',
      top: '',
      left: '',
    })
      .removeClass('scrLock');
    const pos = body.attr('data-scroll');
    body.data('scroll', '');
    $('html, body').prop({scrollTop: pos});
  }
}

export default scrLock;