export function device() {
  const ua = navigator.userAgent;
  if (
    ua.indexOf('iPhone') > 0
    || ua.indexOf('iPod') > 0
    || (ua.indexOf('Android') > 0 && ua.indexOf('mb') > 0)
  ) {
    return 'sp';
  } else if (
    ua.indexOf('iPad') > 0
    || ua.indexOf('Android') > 0
  ) {
    return 'tb';
  } else {
    return 'pc';
  }
}
export function width() {
  const winW = window.innerWidth;
  if (winW <= 415)       return 'sp';
  else if (winW <= 1200) return 'tb';
  else                   return 'pc';
}
